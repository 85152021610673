import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import theme from "../shared/theme"
import styled from "@emotion/styled"
import Button from "../elements/button"
import Image from "gatsby-image"
import * as BlockContent from "@sanity/block-content-to-react"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"

export const query = graphql`
  {
    sanityAboutMe {
      about_me_headline
      _rawTextAboutMe
      image_about_me {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const AboutMe = ({ data }) => (
  <Layout>
    <Body>
      <SEO
        title="Lumenas Fotograf André Schmidt"
        description="André Schmidt Fotograf, Dozent und Webentwickler aus Berlin. Arbeitet im Bereich der Street Photography und der analogen Fotografie."
      />
      <h1>{data.sanityAboutMe.about_me_headline}</h1>
      <PictureContainer>
        <Image
          fluid={
            data.sanityAboutMe.image_about_me.asset.fluid
              ? data.sanityAboutMe.image_about_me.asset.fluid
              : null
          }
          alt="Porträt des Fotografen André Schmidt im Kollodium Nassplatten Verfahren"
        />
      </PictureContainer>
      <TextContainer>
        <BlockContent
          blocks={
            data.sanityAboutMe._rawTextAboutMe
              ? data.sanityAboutMe._rawTextAboutMe
              : ""
          }
          serializers={{ types: { block: BlockRenderer } }}
        />
      </TextContainer>
      <Button ariaLabel="zur Startseite" linkTo="/">
        ZUR STARTSEITE
      </Button>
    </Body>
  </Layout>
)

const Body = styled.div`
  background-color: ${theme.colors.bodyBackground};
  padding: ${theme.spaces.standardPadding};
  color: ${theme.colors.darkFont};

  h1 {
    font-family: ${theme.fontFamilyHeading};
    text-transform: uppercase;
    padding-bottom: ${theme.spaces.standardPadding};
    font-size: calc(2 * ${theme.fontsizes.h1MediumScreen});
  }
`

const PictureContainer = styled.div`
  width: 50%;
  margin: auto;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`

const TextContainer = styled.div`
  width: 50%;
  margin: auto;
  padding: ${theme.spaces.standardPadding} 0 ${theme.spaces.standardPadding} 0;
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};
  text-align: justify;

  @media all and (max-width: 768px) {
    width: 100%;
    font-size: ${theme.fontsizes.textSmallScreen};
    line-height: ${theme.spaces.textSmallScreenLineHeight};
  }
`

export default AboutMe
